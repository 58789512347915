import React, { Component } from 'react';
import * as images from '../assets/images/portfolio'

class Portfolio extends Component {

    render() {
        return (
             <div>
                 <Header />
                 <PortfolioGrid/>
             </div>
        )
    }
}
export default Portfolio

function Header() {
    return (
        /*Header*/
        <header class="header text-center first-section">
            <div class="container d-flex align-items-center flex-column header-animate">
                {/*<img class="header-profile-picture mb-" src="assets/img/eu.jpg" alt="">*/}
                <h1 class="text-uppercase font-weight-bold">Ronaldo Rosa Junior</h1>
                <h3>Web Developer Full Stack - HTML/CSS - Javascript - React - PHP</h3>
                <p>Especializado em desenvolvimento web e móvel com 5+ anos de experiência criando websites e aplicações em diversas tecnologias.</p>
            </div>
            <div class="text-center mt-4">
                <a class="btn btn-lg btn-dark btn-contact" href="/contact">
                    Contato
                </a>
                <a class="btn btn-lg btn-outline-dark" href="/about">
                    Currículo
                </a>
            </div>
        </header>
    )
}

function PortfolioGrid () {

    return (
        <div className="view portfolio-animate container text-center section ">
            <h1 className="portfolio-title text-uppercase">Portfolio</h1>
            <div className="portfolio">
                <div className="row justify-content-center">
                    <div className="row justify-content-center">
                        <PortfolioItem image={images.arquimedia}/>
                        <PortfolioItem image={images.conferencia}/>
                        <PortfolioItem image={images.olmos}/>
                        <PortfolioItem image={images.jornalcompas}/>
                        <PortfolioItem image={images.mulheremidia}/>
                        <PortfolioItem image={images.wsf}/>
                    </div>
                </div>
            </div>
        </div>

    );
}

function PortfolioItem(props) {
    return (
        <div className="col-md-6 col-lg-4 mb-5" data-toggle="modal" data-target="#portfolioModal6">
            <div className="portfolio-item mx-auto d-flex justify-content-center shadow">
                <div className="portfolio-item-caption d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="portfolio-item-caption-content text-center text-white">
                        <i className="fas fa-plus fa-3x"/>
                    </div>
                </div>
                <img className="img-fluid" src={props.image} alt=""/>
            </div>
        </div>
    )
}
