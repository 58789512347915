import React, { Component } from 'react';

class Contact extends Component {

    render() {
        return (
            /*Contact*/
            <div class="container first-section section view">
                {/*Contact Heading*/}
                <h1 class="text-center text-uppercase">Contato</h1>

                {/*Contact Form*/}
                <div class="row content">
                    <div class="col-lg-8 mx-auto">
                        {/*To configure the contact form email address go to mail/contact_me.php*/}
                        <form id="contactForm" name="sentMessage" novalidate="novalidate">
                            <div class="control-group">
                                <div class="form-group floating-label-form-group control mb-o pb-2">
                                    <label for="name">Nome</label>
                                    <input type="text" class="form-control" id="name" placeholder="Nome" required="required" data-validation-required-message="Qual é o seu nome?" />
                                    <p class="help-block text-danger"></p>
                                </div>
                            </div>
                            <div class="control-group">
                                <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                    <label for="email">Email</label>
                                    <input type="text" class="form-control" id="email" placeholder="Email" required="required" data-validation-required-message="Por favor informe seu email." />
                                    <p class="help-block text-danger"></p>
                                </div>
                            </div>
                            <div class="control-group">
                                <div class="form-group floating-label-form-group controls mb-0 pb-2">
                                    <label for="message">Mensagem</label>
                                    <textarea id="message" rows="5" class="form-control" placeholder="Mensagem" required="required" data-validation-required-message="Deixe uma mensagem."></textarea>
                                    <p class="help-block text-danger"></p>
                                </div>
                            </div>
                            <br />
                            <div id="success"></div>
                            <div class="form-group">
                                <button class="btn btn-dark btn-xl contact-submit" id="sendMessageButton" type="submit">Enviar</button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        )
    }
}
export default Contact