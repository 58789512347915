import {
    TimelineMax as Timeline,
    Power1
} from 'gsap';

const getDefaultTimeline = (node, delay) => {
    const timeline = new Timeline({ paused: true });
    const content = node.querySelector('.content');

    timeline
        .from(node, 0.3, { autoAlpha: 0, delay, ease: Power1.easeIn })
        .from(content, 0.8, { autoAlpha: 0, y: 25, ease: Power1.easeInOut })

    return timeline;
}

const getHomeTimeline = (node, delay) => {
    const timeline = new Timeline({ pause: true});
    const header = node.querySelectorAll('.header-animate > h1, h3, p, a');
    const portfolio = node.querySelector('.portfolio-animate');

    timeline
        .from(node, 0, {display: 'none', autoAlpha: 0, delay })
        .staggerFrom(header, 0.375, { autoAlpha: 0, x: -25, ease: Power1.easeOut }, 0.125)
        .from(portfolio, 0.8, { autoAlpha: 0, y: 25, ease: Power1.easeInOut })

    return timeline;
}

export const play = (pathname, node, appears) => {
    const delay = appears ? 0 : 0.5;
    let timeline

    if (pathname === '/')
        timeline = getHomeTimeline(node, delay);
    else
        timeline = getDefaultTimeline(node, delay);

    window
        .loadPromise
        .then(() => requestAnimationFrame(() => timeline.play()))
}

export const exit = (node) => {
    const timeline = new Timeline({ paused: true });

    timeline.to(node, 0.15, {autoAlpha: 0, ease: Power1.easeOut});
    timeline.play();
}