import React, { Component } from 'react';
import * as images from '../assets/images/portfolio'

class Portfolio extends Component {
    render() {
        return (
            <div>
                <h2>About</h2>
            </div>
        )
    }
}
export default Portfolio
