import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink
} from "react-router-dom";
import { Transition, TransitionGroup } from "react-transition-group";
import {play, exit} from "./timelines";

import Portfolio from "./views/Portfolio";
import About from "./views/About";
import Contact from "./views/Contact";

import './App.css';

/* Work around for the production build. CSSPlugin is not loaded into build if not being used https://github.com/greensock/GSAP/issues/285 */
import CSSPlugin from 'gsap/CSSPlugin';

const C = CSSPlugin;  // here is the gotcha....

function OnSelected(e) {
  // doesn't need to have functionality (necessarily) ... just wired up
}

function App() {

  return (

    <div className="App">
      {/*Navigation*/}
      <Router>

        <nav class="navbar navbar-expand-lg navbar-light">
          <div class="container">
            <a class="navbar-brand text-uppercase font-weight-bold" href="/">Lekapo</a>
            <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarNav">
              <ul class="navbar-nav ml-auto">
                <li class="nav-item mx-0 mx-lg-1">
                  <NavLink data-toggle="collapse" data-target=".navbar-collapse" className="nav-link py-3 px-0 px-lg-3" to='/' exact={true}>Portfolio</NavLink>
                </li>
                <li class="nav-item mx-0 mx-lg-1">
                  <NavLink data-toggle="collapse" data-target=".navbar-collapse" className="nav-link py-3 px-0 px-lg-3" to='/about'>About</NavLink>
                </li>
                <li class="nav-item mx-0 mx-lg-1">
                  <NavLink data-toggle="collapse" data-target=".navbar-collapse" className="nav-link py-3 px-0 px-lg-3" to='/contact'>Contact</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <Route render={({ location }) => {
          const {pathname, key} = location;

          return(
              <TransitionGroup component={null}>
                <Transition
                    key={key}
                    appear={true}
                    onEnter={(node, appears) => play(pathname, node, appears)}
                    onExit={(node, appears) => exit(node, appears)}
                    timeout={{enter: 750, exit: 150}}
                >
                  <Switch location={location}>
                    <Route exact path='/' component={Portfolio} />
                    <Route exact path='/about' component={About} />
                    <Route exact path='/contact' component={Contact} />
                  </Switch>

                </Transition>
              </TransitionGroup>
          )
        }} />

        {/*Footer*/}
        <footer class="align-items-center text-center">

          <a class="btn btn-outline-dark btn-social mx-1" href="https://facebook.com/lekapo"><i class="fab fa-fw fa-facebook-f"></i></a>
          <a class="btn btn-outline-dark btn-social mx-1" href="https://github.com/lekapo"><i class="fab fa-github"></i></a>
          <a class="btn btn-outline-dark btn-social mx-1" href="https://www.linkedin.com/in/ronaldo-rosa-junior/"><i class="fab fa-fw fa-linkedin-in"></i></a>

          <a href="mailto:lekapo@gmail.com">
            <h2 class="font-weight-bold">lekapo@gmail.com</h2>
          </a>
          <small class="copyright">Copyright © Lekapo, All rights reserved</small>
        </footer>

      </Router>


    </div>
  );
}

export default App;

